import { useUser } from '../../app/hooks/useUser';
import { type OrganisationReportingEntity } from '../types';

export const useOrganisationCompanyList = (): OrganisationReportingEntity[] => {
  const user = useUser();

  return Object.keys(user.data_by_company).map((companyId) => {
    const company = user.data_by_company[companyId];
    return {
      id: companyId,
      name: company.name,
      country: company.country,
      currency: company.currency,
      type: company.type,
      isChurning: Boolean(company.churning_at),
      hasChurned: Boolean(company.churned_at),
    };
  });
};
