import { Tag, Popover, type TriggerProps } from '@dev-spendesk/grapes';
import React, { type ReactNode } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import { LogoutButton } from 'common/components/LogoutButton/LogoutButton';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import {
  GringottsContainer,
  GringottsProfileItem,
} from 'modules/app/gringotts';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import CONFIG from 'src/core/config';
import FEATURES from 'src/core/constants/features';
import { routes, routeFor } from 'src/core/constants/routes';

import { useFeatureExperimentQuery } from '../../hooks/useFeatureExperiment';

import './AvatarMenu.css';

type AvatarMenuPopoverProps = {
  position?: 'bottom-end' | 'top-start' | 'end-top';
  className?: string;
  children: (triggerProps: TriggerProps, isOpen: boolean) => ReactNode;
};

export const AvatarMenuPopover = ({
  position = 'bottom-end',
  className,
  children,
}: AvatarMenuPopoverProps) => {
  const { t } = useTranslation('global');
  const user = useUser();
  const company = useCompany();
  const queryClient = useQueryClient();

  const hasNewNavigation = useFeature(FEATURES.TMP_VERTICAL_NAVIGATION);
  const hasPlasticCardsFeature = useFeature(FEATURES.PLASTIC_CARDS);

  const featureExperimentQueryState = useFeatureExperimentQuery({
    experimentName: 'summer-release-2024-modal',
  });
  const featureExperimentResult = unwrapQuery(featureExperimentQueryState);
  const shouldSeeReleaseItem =
    featureExperimentResult?.outcome === 'success' &&
    featureExperimentResult?.variant === 'show-modal';

  // TODO: Remove this function once TMP_VERTICAL_NAVIGATION is removed
  const maybeRenderPlasticCardLink = (close: () => void) => {
    const plasticCard = user?.data_by_company?.[company.id]?.plastic_card;
    if (
      company.churning_at ||
      !plasticCard ||
      !user.is_admin ||
      !hasPlasticCardsFeature ||
      hasNewNavigation
    ) {
      return null;
    }

    return (
      <li className="AvatarMenu__dropdown-item">
        <Link
          to={routeFor(routes.CARD.path, { company: company.id })}
          onClick={close}
        >
          {t('dropdownLinks.card')}
        </Link>
      </li>
    );
  };

  const maybeRenderGringottsButton = () => {
    if (!CONFIG.gringottsEnabled || !company.banking_provider) {
      return null;
    }

    return (
      <li className="AvatarMenu__dropdown-item">
        <GringottsContainer
          companyId={company.id}
          bankingProvider={company.banking_provider}
        >
          {(toggleGringottsIframe) => {
            return (
              <GringottsProfileItem
                onToggleGringottsIframe={toggleGringottsIframe}
              />
            );
          }}
        </GringottsContainer>
      </li>
    );
  };

  const maybeRenderClearQueriesButton = () => {
    if (process.env.NODE_ENV !== 'development') {
      return null;
    }

    return (
      <li className="AvatarMenu__dropdown-item">
        <button
          type="button"
          className="rounded-none text-inherit outline-non flex h-full w-full cursor-pointer items-center border-none no-underline title-m"
          style={{ background: 'inherit' }}
          onClick={() => {
            queryClient.invalidateQueries({
              active: true,
              inactive: true,
              stale: true,
            });
            queryClient.clear();
          }}
        >
          Clear cached queries
          <Tag className="ml-xs" variant="warning">
            Dev
          </Tag>
        </button>
      </li>
    );
  };

  return (
    <Popover
      placement={position}
      className={className}
      renderTrigger={children}
    >
      {(close) => (
        <ul className="AvatarMenu__dropdown">
          <li className="AvatarMenu__dropdown-item">
            <Link
              to={routeFor(routes.ACCOUNT_ME_PROFILE.path, {
                company: company.id,
              })}
              onClick={close}
            >
              {t('dropdownLinks.profile')}
            </Link>
          </li>
          {maybeRenderPlasticCardLink(close)}
          <li className="AvatarMenu__dropdown-item">
            <a
              href="https://helpcenter.spendesk.com"
              target="_blank"
              rel="noopener noreferrer"
              onClick={close}
            >
              {t('dropdownLinks.help')}
            </a>
          </li>
          <li className="AvatarMenu__dropdown-item">
            <Link
              to={routeFor(routes.LEGALS.path, { company: company.id })}
              onClick={close}
            >
              {t('dropdownLinks.legals')}
            </Link>
          </li>
          {/* TODO(GROW-1309): display item to everyone */}
          {shouldSeeReleaseItem && (
            <li className="AvatarMenu__dropdown-item">
              <a
                href="https://www.spendesk.com" // TODO(GROW-1308): update link
                target="_blank"
                rel="noopener noreferrer"
                onClick={close}
              >
                {t('dropdownLinks.newReleases')}
              </a>
            </li>
          )}
          {maybeRenderGringottsButton()}
          {maybeRenderClearQueriesButton()}
          <li className="AvatarMenu__dropdown-item">
            <LogoutButton />
          </li>
        </ul>
      )}
    </Popover>
  );
};
