import { Icon } from '@dev-spendesk/grapes';
import cx from 'classnames';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import onClickOutside from 'react-onclickoutside';
import TagsInput from 'react-tagsinput';

import Tag from 'src/core/common/components/legacy/Tag/Tag';

import 'react-tagsinput/react-tagsinput.css';
import './InputTagsSelect.scss';

const getScrollOffset = (element) =>
  element.offsetTop - element.parentElement.scrollTop;

class InputTagsSelect extends Component {
  static propTypes = {
    selectedOptions: PropTypes.arrayOf(PropTypes.string),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        subItems: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }),
        ),
      }),
    ),
    className: PropTypes.string,
    createNewItemText: PropTypes.string,
    allowCreateNewItem: PropTypes.bool,
    getTagDisplayTheme: PropTypes.func,
    getTagDisplayValue: PropTypes.func,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedOptions: [],
    options: null,
    className: undefined,
    createNewItemText: '',
    allowCreateNewItem: true,
    getTagDisplayTheme: () => 'info',
    getTagDisplayValue: identity,
  };

  constructor(props) {
    super(props);
    this.state = {
      hoveredMenuItem: null,
      hoveredMenuItemOffset: 0,
      showOptions: false,
      shouldSuggestCreation: false,
      newStaticValue: null,
    };
    this.tagInput = null;
    this.closeTimeout = null;
  }

  handleClickOutside = () => this.setState({ showOptions: false });

  handleChange = (tags) => {
    this.props.onChange(tags);
    this.setState({
      shouldSuggestCreation: false,
      newStaticValue: '',
    });
  };

  handleTyping = (e) => {
    const content = e.target.value;
    this.setState({
      newStaticValue: content,
      shouldSuggestCreation: !isEmpty(content),
    });
  };

  handleHover = (option, e) => {
    clearTimeout(this.closeTimeout);

    const subOptions = option.subItems;
    const hasSubOptions = !isEmpty(subOptions);
    this.setState({
      hoveredMenuItem: hasSubOptions ? option : null,
      hoveredMenuItemOffset: getScrollOffset(e.currentTarget),
    });
  };

  canShowAvailableChoices = () => {
    const { allowCreateNewItem, options } = this.props;
    const { showOptions, shouldSuggestCreation } = this.state;

    return (
      !isEmpty(options) && // has options
      showOptions && // can show them (focused on input)
      (!allowCreateNewItem || !shouldSuggestCreation)
    ); // not typing
  };

  canShowCreateNewItemChoice = () => {
    const { allowCreateNewItem } = this.props;
    const { shouldSuggestCreation } = this.state;
    return shouldSuggestCreation && allowCreateNewItem;
  };

  selectChoice = (option, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const nextSelectedOptions = (this.props.selectedOptions ?? []).concat(
      option.id,
    );
    this.props.onChange(nextSelectedOptions);
    this.setState({
      shouldSuggestCreation: false,
      hoveredMenuItem: null,
      newStaticValue: '',
    });

    this.tagInput.focus();
  };

  renderTag = ({ tag, key, disabled, onRemove }) => {
    const { getTagDisplayTheme, getTagDisplayValue } = this.props;
    return (
      <Tag
        key={key}
        theme={getTagDisplayTheme(tag)}
        text={getTagDisplayValue(tag)}
        onClick={() => onRemove(key)}
        hasCloseIcon
        isDisabled={disabled}
      />
    );
  };

  renderNewStaticChoice = () => {
    const { createNewItemText, t } = this.props;
    const { newStaticValue } = this.state;

    if (!this.canShowCreateNewItemChoice()) {
      return;
    }

    return (
      <div className="InputTagsSelect__choices is-static-value">
        <button
          type="button"
          className="InputTagsSelect__choices-item"
          onClick={() => this.selectChoice({ id: newStaticValue })}
        >
          <span>{`+ ${createNewItemText ?? t('misc.createNewValue')}`}</span>
        </button>
      </div>
    );
  };

  renderAvailableChoices = () => {
    const { options } = this.props;

    if (!this.canShowAvailableChoices()) {
      return;
    }

    return (
      <div className="InputTagsSelect__choices">
        {map(options, (option) => {
          const subRecommended = option.subItems;
          const hasSubRecommendedItems = !isEmpty(subRecommended);

          return (
            <button
              type="button"
              key={option.id}
              className="InputTagsSelect__choices-item"
              onMouseEnter={(e) => this.handleHover(option, e)}
              onClick={(e) =>
                !hasSubRecommendedItems && this.selectChoice(option, e)
              }
            >
              <span>{option.name}</span>
              {hasSubRecommendedItems && <Icon name="caret-right" />}
            </button>
          );
        })}
      </div>
    );
  };

  renderSubChoices = () => {
    const { hoveredMenuItem, hoveredMenuItemOffset } = this.state;

    if (!hoveredMenuItem || !hoveredMenuItem.subItems) {
      return;
    }

    return (
      <div
        className="InputTagsSelect__subchoices"
        style={{ transform: `translateY(${hoveredMenuItemOffset}px)` }}
      >
        {map(hoveredMenuItem.subItems, (subItem) => (
          <button
            type="button"
            key={subItem.id}
            className="InputTagsSelect__subchoices-item"
            onMouseEnter={() => clearTimeout(this.closeTimeout)}
            onClick={(e) => this.selectChoice(subItem, e)}
          >
            {subItem.name}
          </button>
        ))}
      </div>
    );
  };

  render() {
    const { className, t } = this.props;
    const { newStaticValue } = this.state;

    const inputProps = {
      placeholder: t('exports.entryId.selectOrType'),
      onFocus: () => this.setState({ showOptions: true }),
      onChange: this.handleTyping,
      value: newStaticValue || '',
      ref: (element) => {
        this.tagInput = element;
      },
    };

    return (
      <div
        className="InputTagsSelect"
        onMouseLeave={() => {
          // do not close instantly as there is spacing between the two menus
          this.closeTimeout = setTimeout(() => {
            this.setState({ hoveredMenuItem: null });
          }, 100);
        }}
      >
        <TagsInput
          className={cx('InputTagsSelect__selector', className)}
          value={this.props.selectedOptions}
          renderTag={this.renderTag}
          onChange={this.handleChange}
          inputProps={inputProps}
        />
        {this.renderAvailableChoices()}
        {this.renderSubChoices()}
        {this.renderNewStaticChoice()}
      </div>
    );
  }
}

export default withTranslation()(onClickOutside(InputTagsSelect));
