import React from 'react';

import { LogoutButton } from 'common/components/LogoutButton/LogoutButton';
import { useSupervisor } from 'modules/app/hooks/useSupervisor';
import { useUser } from 'modules/app/hooks/useUser';

import './Supervision.css';

export const Supervision = () => {
  const supervisor = useSupervisor();
  const user = useUser();
  if (!supervisor || !user) {
    return null;
  }

  return (
    <div className="Supervision">
      Welcome {supervisor.name}! You are logged in as {user.fullname}.{' '}
      <LogoutButton />
    </div>
  );
};
