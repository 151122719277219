/* eslint-disable @typescript-eslint/naming-convention */

export enum AnalyticEventName {
  // Expense Claims
  EXPENSE_CLAIMS_REJECT_OPEN = 'exp_cl:review:rejection_modal:opened',
  EXPENSE_CLAIMS_REJECT_CONFIRMED = 'exp_cl:review:rejection_modal:confirmed',
  EXPENSE_CLAIMS_REJECT_CANCEL = 'exp_cl:review:rejection_modal:closed',
  EXPENSE_CLAIMS_SEND_EMAIL_CLICKED = 'exp_cl:review:reminder_email:clicked',
  EXPENSE_CLAIMS_DISCUSS_ON_SLACK_CLICKED = 'exp_cl:review:reminder_slack:clicked',
  EXPENSE_CLAIMS_USER_CLICKED = 'exp_cl:pay:sch_payment_details:opened',
  EXPENSE_CLAIMS_SEND_TO_PAYMENT_CLICKED = 'exp_cl:pay:pay:clicked',
  EXPENSE_CLAIMS_DOWNLOAD_CSV_FOR_MISSING_BANK_INFO_USERS = 'exp_cl:pay:pay_via_csv_missing_bank_info:clicked',
  EXPENSE_CLAIMS_HISTORY_SCHEDULED_PAYMENT_CLICKED = 'exp_cl:history:sch_payment_details:opened',
  EXPENSE_CLAIMS_SEND_MISSING_BANK_INFO_REMINDER = 'exp_cl:pay:ask_bank_info:clicked',
  EXPENSE_CLAIM_DUPLICATE_DISPLAYED = 'exp_cl:duplicate:displayed',
  EXPENSE_CLAIM_DUPLICATE_CONTINUE_CLICKED = 'exp_cl:duplicate:continue:clicked',
  EXPENSE_CLAIM_DUPLICATE_DELETE_CLICKED = 'exp_cl:duplicate:delete:clicked',

  // Invoices
  INVOICES_DL_AND_MARK_AS_PAID_CLICKED = 'invoice:pay:download_and_mark_as_paid:clicked',

  // review
  INVOICES_VALIDATE_CLICKED = 'invoice:review:validate:clicked',
  INVOICES_REVIEW_REJECTION_MODAL_OPENED = 'invoice:review:rejection_modal:confirmed',
  INVOICES_REVIEW_REJECTION_MODAL_CONFIRMED = 'invoice:review:rejection_modal:opened',
  INVOICES_REVIEW_REJECTION_MODAL_CLOSED = 'invoice:review:rejection_modal:closed',

  // Invoices -> Schedule
  INVOICES_SCHEDULE_CONFIRMATION_MODAL_OPENED = 'invoice:schedule:confirmation_modal:opened',
  INVOICES_SCHEDULE_CONFIRMATION_MODAL_CONFIRMED = 'invoice:schedule:confirmation_modal:confirmed',
  INVOICES_SCHEDULE_CONFIRMATION_MODAL_CLOSED = 'invoice:schedule:confirmation_modal:closed',
  INVOICES_SCHEDULE_TRY_WIRE_TRANSFER_FEATURE_BUTTON_CLICKED = 'invoice:schedule:try_wire_transfer_feature_button:clicked',
  INVOICES_SCHEDULE_NOT_INTERESTED_IN_WIRE_TRANSFER_FEATURE_BUTTON_CLICKED = 'invoice:schedule:not_interested_in_wire_transfer_feature_button:clicked',
  INVOICES_SCHEDULE_FIND_OUT_MORE_WIRE_TRANSFER_FEATURE_BUTTON_CLICKED = 'invoice:schedule:find_out_more_wire_transfer_feature_button:clicked',
  INVOICES_SCHEDULE_COMPLIANCE_MODAL_OPENED = 'invoice:schedule:compliance_modal:opened',

  // Invoices -> Confirm
  INVOICES_CONFIRM_SEND_REMINDER_CLICKED = 'invoice:confirm:send_reminder:clicked',
  INVOICES_CONFIRM_ADD_FUNDS_CLICKED = 'invoice:confirm:add_funds:clicked',

  INVOICES_CONFIRM_REJECTION_MODAL_OPENED = 'invoice:confirm:rejection_modal:opened',
  INVOICES_CONFIRM_REJECTION_MODAL_CONFIRMED = 'invoice:confirm:rejection_modal:confirmed',
  INVOICES_CONFIRM_REJECTION_MODAL_CLOSED = 'invoice:confirm:rejection_modal:closed',

  INVOICES_CONFIRM_CONFIRMATION_MODAL_OPENED = 'invoice:confirm:confirmation_modal:opened',
  INVOICES_CONFIRM_CONFIRMATION_MODAL_CONFIRMED = 'invoice:confirm:confirmation_modal:confirmed',
  INVOICES_CONFIRM_CONFIRMATION_MODAL_CLOSED = 'invoice:confirm:confirmation_modal:closed',

  INVOICES_CONFIRM_TRY_WIRE_TRANSFER_FEATURE_BUTTON_CLICKED = 'invoice:confirm:try_wire_transfer_feature_button:clicked',

  // Invoices -> history
  INVOICES_HISTORY_PROOF_OF_PAYMENT_BUTTON_CLICKED = 'invoice:history:proof_of_payment_button:clicked',
  INVOICES_HISTORY_RESOLVE_WIRE_TRANSFER_ERROR_BUTTON_CLICKED = 'invoice:history:resolve_wire_transfer_error_button:clicked',

  // invoice wire transfer activation
  SETTINGS_PAYMENT_METHODS_PAY_INVOICES_BY_WIRE_TRANSFER_ACTIVATED = 'settings:payment_methods:pay_invoice_by_wiretransfer:activated',

  // Expense
  EXPENSE_PROOF_DOWNLOADED = 'expense:proof:downloaded',

  // Expense Inbox
  EXPENSE_INBOX_SORT_CHANGED = 'expense_inbox:sort_changed',
  EXPENSE_INBOX_GROUP_CHANGED = 'expense_inbox:group_changed',
  EXPENSE_INBOX_PERIOD_CHANGED = 'expense_inbox:period_changed',
  EXPENSE_INBOX_FILTER_CHANGED = 'expense_inbox:filter_changed',
  EXPENSE_INBOX_FILTERS_RESET = 'expense_inbox:filters_reset',
  EXPENSE_INBOX_SEARCH_CHANGED = 'expense_inbox:search_changed',

  // Drafts
  DRAFT_DUPLICATE_CONTINUE_BUTTON_CLICKED = 'draft:duplicate:continue_button:clicked',
  DRAFT_DUPLICATE_DELETE_BUTTON_CLICKED = 'draft:duplicate:delete_button:clicked',
  DRAFT_INVOICE_UPLOADED = 'draft:invoice:uploaded',
  DRAFT_UNDER_ANALYSIS_DELETE_BUTTON_CLICKED = 'draft:under_analysis:delete_button:clicked',
  DRAFT_UNDER_ANALYSIS_LET_PROCESS_BUTTON_CLICKED = 'draft:under_analysis:let_process_button:clicked',
  DRAFT_UNDER_ANALYSIS_SKIP_PROCESSING_BUTTON_CLICKED = 'draft:under_analysis:skip_processing_button:clicked',

  // Onboarding
  ONBOARDING_ACCESSING_TO_APP = 'onboarding:accessing_to_app',
  ONBOARDING_CONTINUE_LATER = 'onboarding_continue_later',
  ONBOARDING_ENRICH_COMPANY = 'onboarding_enrich_company',
  ONBOARDING_SELECT_COMPANY_RESULT = 'onboarding_select_company_result',
  ONBOARDING_ADD_CUSTOM_COMPANY = 'onboarding_add_custom_company',
  ONBOARDING_SKIP_LOAD_FUNDS = 'onboarding_skip_load_funds',
  ONBOARDING_RESEND_EMAIL_CONFIRMATION = 'onboarding_resend_email_confirmation',
  ONBOARDING_STEP_COMPLETED = 'onboarding_step_completed',

  // Restricted warning
  RESTRICTED_JUSTIFY_PAYMENTS_BUTTON_CLICKED = 'restricted_warning:justify_payments_button:clicked',

  // Homepage
  HOMEPAGE_SWITCH_MODE_CLICKED = 'homepage:switch_mode:clicked',
  HOMEPAGE_REQUESTER_HOW_LINK_CLICKED = 'homepage:requester:how_link:clicked',

  // Controller homepage
  // TODO: migrate event names (from dashboard to homepage) + prefix with "controller"
  HOMEPAGE_REQUESTS_TO_REVIEW_BUTTON_CLICKED = 'dashboard:actions:requests_to_review_button:clicked',
  HOMEPAGE_INVOICES_TO_PAY_BUTTON_CLICKED = 'dashboard:actions:invoices_to_pay_button:clicked',
  HOMEPAGE_INVOICE_TRANSFERS_TO_CONFIRM_BUTTON_CLICKED = 'dashboard:actions:invoice_transfers_to_confirm_button:clicked',
  HOMEPAGE_EXPENSE_TRANSFERS_TO_CONFIRM_BUTTON_CLICKED = 'dashboard:actions:expense_transfers_to_confirm_button:clicked',
  HOMEPAGE_MISSING_RECEIPTS_BUTTON_CLICKED = 'dashboard:actions:missing_receipts_button:clicked',
  HOMEPAGE_PAYMENTS_TO_PREPARE_BUTTON_CLICKED = 'dashboard:actions:payments_to_prepare_button:clicked',
  HOMEPAGE_INVITE_TEAMMATES_BUTTON_CLICKED = 'dashboard:actions:invite_teammates_button:clicked',
  HOMEPAGE_SETUP_ACCOUNTING_BUTTON_CLICKED = 'dashboard:actions:setup_accounting_button:clicked',
  HOMEPAGE_WALLET_DETAILS_BUTTON_CLICKED = 'dashboard:wallet:view_details_button:clicked',
  HOMEPAGE_WALLET_ADD_FUNDS_BUTTON_CLICKED = 'dashboard:wallet:add_funds_button:clicked',
  HOMEPAGE_SPEND_DOWNLOAD_BUTTON_CLICKED = 'dashboard:spend_graph:download_button:clicked',
  HOMEPAGE_LEADERBOARD_NEXT_PAGE_BUTTON_CLICKED = 'dashboard:leaderboard:next_page_button:clicked',
  HOMEPAGE_LEADERBOARD_PREVIOUS_PAGE_BUTTON_CLICKED = 'dashboard:leaderboard:previous_page_button:clicked',
  HOMEPAGE_LEADERBOARD_ALL_BUTTON_CLICKED = 'dashboard:leaderboard:all_button:clicked',
  HOMEPAGE_TIPS_APPEARED = 'dashboard:tips:appeared',
  HOMEPAGE_TIPS_CLICKED = 'dashboard:tips:clicked',

  // Terms And Conditions
  TAC_MODAL_OPENED = 'terms_and_conditions:modal:opened',
  TAC_MODAL_DISMISSED = 'terms_and_conditions:modal:dismissed',
  TAC_ACCEPTED = 'terms_and_conditions:accepted',
  TAC_LINK_OPENED = 'terms_and_conditions:link:opened',

  // Supplier
  SUPPLIER_SUGGESTION_DISPLAYED = 'supplier:suggestion:displayed',
  SUPPLIER_BANK_INFOS_HISTORY_BUTTON_CLICKED = 'supplier:bank_infos:button:clicked',

  // Card
  CARD_CONTROL_SETTINGS_HOURS_INVALID = 'card:control-settings:hours:invalid',
  RECARD_ORDERED = 'recard:new_card_requested',
  RECARD_BANNER_CLICKED = 'recard:top:banner:recard:clicked',
  CARD_NOT_RECEIVED_CLICKED = 'cards:not_received_card:top_banner:button_clicked',
  CARD_NOT_RECEIVED_CONFIRMED = 'cards:not_received_card:confirm:button_clicked',
  CARD_NOT_RECEIVED_ORDER_NEW = 'cards:not_received_card:order_new_card',
  CARD_REQUEST_GET_CARD_BUTTON_CLICKED = 'cards:request:get_card:button_clicked',
  CARD_ACCESS_PIN = 'card:page:access_pin_clicked',
  CARD_SEE_PIN_REVEAL = 'card:page:see_pin_clicked',
  CARD_SEE_OTP_REVEAL = 'card:page:see_otp_clicked',
  CARD_CLOSE_PIN_REVEAL = 'card:page:close_pin_reveal',
  CARD_CLOSE_OTP_REVEAL = 'card:page:close_otp_reveal',
  CARD_PIN_REVEAL_SUCCESS = 'card:page:card_pin_reveal_success',

  // Legacy (before the TS analytics service)
  CLICKED_GET_FORWARD_INFO = 'clicked_get_forward_info',
  CLICKED_SUPPLIER_BILLING_LINK = 'clicked_supplier_billing_link',
  EDITED_CUSTOM_EXPORT = 'edited_custom_export',
  EXPENSE_ACCOUNT_RULE_EXCEPTION_CREATED = 'expense_account_rule_exception_created',
  GOT_FORWARD_INFO = 'got_forward_info',
  HELP_CENTER_CLICKED_BUTTON = 'help_center_clicked_button',
  HELP_CENTER_CLICKED_CHAT = 'help_center_clicked_chat',
  HELP_CENTER_CLICKED_LINK = 'help_center_clicked_link',
  INVOICE_TO_REVIEW_MAILTO = 'invoice_to_review_mailto',
  PAYMENT_TO_REVIEW_MAILTO = 'payment_to_review_mailto',
  PAYMENT_UPDATED = 'payment_updated',
  RECEIPT_DELETED = 'receipt_deleted',
  SUPPLIER_RULE_CREATED = 'supplier_rule_created',
  SUPPLIER_RULE_UPDATED = 'supplier_rule_updated',

  // Bookkeeping
  BOOKKEEP_SUPPLIER_RULE_CREATED = 'bookkeep:supplier_rule_created',
  BOOKKEEP_SUPPLIER_RULE_UPDATED = 'bookkeep:supplier_rule_updated',
  BOOKKEEP_EXPORT_PERIOD_CHANGED = 'bookkeep:export:period_changed',
  BOOKKEEP_EXPORT_REQUESTED = 'bookkeep:export:requested',
  BOOKKEEP_EXPORT_REVIEW_PAGE_BUTTON_CLICKED = 'bookkeep:export:review_page_button_clicked',
  BOOKKEEP_EXPORT_NEW_RECEIPTS_BANNER_HIDE_CLICKED = 'bookkeep:export:new_receipts_banner_hide_clicked',
  BOOKKEEP_EXPORT_UNPREPARED_EXPENSES_WARNING_CLICKED = 'bookkeep:export:unprepared_expenses_warning_clicked',
  BOOKKEEP_EXPORT_EXPENSE_SENT_BACK_TO_PREPARE = 'bookkeep:export:expense_sent_back_to_prepare',
  BOOKKEEP_EXPORT_EXPENSE_MARKED_AS_MANUALLY_EXPORTED = 'bookkeep:export:expense_mark_as_manually_exported',
  BOOKKEEP_EMPLOYEE_ACCOUNT_CREATED = 'bookkeep:employee_account_created',
  BOOKKEEP_PREPARE_SET_DEDICATED_SUPPLIER_ACCOUNT_CLICKED = 'bookkeep:prepare:set_dedicated_supplier_account_clicked',
  BOOKKEEP_PREPARE_SET_MISCELLANEOUS_ACCOUNT_CLICKED = 'bookkeep:prepare:set_miscellaneous_account_clicked',
  BOOKKEEP_PREPARE_PAYABLE_SELECTED = 'bookkeep:prepare:payable_selected',
  BOOKKEEP_PREPARE_PAYABLE_EDITED = 'bookkeep:prepare:payable_edited',
  BOOKKEEP_PREPARE_ACCOUNTING_DATE_EDITED = 'bookkeep:prepare:accounting_date_edited',
  BOOKKEEP_PREPARE_PAYABLE_SAVED_OR_MARKED_AS_READY = 'bookkeep:prepare:payable_saved',
  BOOKKEEP_PREPARE_RECEIPT_DECLARED_AS_INVALID = 'bookkeep:prepare:receipt_declared_as_invalid',
  BOOKKEEP_PREPARE_SORTING_CHANGED = 'bookkeep:prepare:sorting_changed',
  BOOKKEEP_PREPARE_GROUPING_CHANGED = 'bookkeep:prepare:grouping_changed',
  BOOKKEEP_PAYABLE_ALL_EXPORT_REQUESTED = 'bookkeep:payable_all:export:requested',
  BOOKKEEP_PAYABLE_ALL_FILTERING_CHANGED = 'bookkeep:payable_all:filtering_changed',
  BOOKKEEP_PAYABLE_ALL_PAYABLE_EDITED = 'bookkeep:payable_all:payable_edited',
  BOOKKEEP_PAYABLE_ALL_RECEIPT_VIEWED = 'bookkeep:payable_all:receipt_viewed',
  BOOKKEEP_SETTINGS_ACCOUNTING_SOFTWARE_SWITCHED = 'bookkeep:settings:accounting_software_switched',
  BOOKKEEP_SETTINGS_ACCOUNTING_SOFTWARE_NOT_SWITCHED = 'bookkeep:settings:accounting_software_not_switched',

  // Subscription
  SUBSCRIPTION_SUPPLIER_BILLING_URL_CLICKED = 'subscriptions:supplier_billing_url_clicked',
  SUBSCRIPTIONS_LIST_VIEW = 'subscriptions:list_view',
  SUBSCRIPTIONS_CARD_VIEW = 'subscriptions:card_view',
  SUBSCRIPTIONS_SEARCH = 'subscriptions:search',
  SUBSCRIPTIONS_PANEL_NAVIGATION = 'subscriptions:panel:navigation',
  SUBSCRIPTIONS_ACTIONS = 'subscriptions:actions',
  SUBSCRIPTIONS_OWNER_SELECTED = 'subscriptions:owner_changed',

  // Budgets
  BUDGETS_BUDGETARY_EXERCISE_DROPDOWN_MENU_BUTTON_CLICKED = 'budgets:budgetary_exercise:dropdown_menu_button_clicked',
  BUDGETS_BUDGETARY_EXERCISE_EDIT_BUTTON_CLICKED = 'budgets:budgetary_exercise:edit_button_clicked',
  BUDGETS_BUDGETARY_EXERCISE_DELETE_BUTTON_CLICKED = 'budgets:budgetary_exercise:delete_button_clicked',

  BUDGETS_EXPORT_BUDGETS_EXPORT_SINGLE_BUDGET_CLICKED = 'budgets:export_budgets:export_single_budget_clicked',
  BUDGETS_EXPORT_BUDGETS_EXPORT_ALL_BUDGETS_CLICKED = 'budgets:export_budgets:export_all_budgets_clicked',

  BUDGETS_PERIODICITY_BUTTON_CLICKED = 'budgets:periodicity:button_clicked',

  BUDGETS_UPDATE_BUDGET_DOWNLOAD_TEMPLATE_BUTTON_CLICKED = 'budgets:update_budget:download_template_button_clicked',
  BUDGETS_UPDATE_BUDGET_UPLOAD_FILE_BUTTON_CLICKED = 'budgets:update_budget:upload_file_button_clicked',
  BUDGETS_UPDATE_BUDGET_UPLOAD_FILE_DRAG_N_DROPPED = 'budgets:update_budget:upload_file_drag_n_dropped',
  BUDGETS_UPDATE_BUDGET_START_IMPORT_BUTTON_CLICKED = 'budgets:update_budget:start_import_button_clicked',

  BUDGETS_REQUEST_PANEL_WIDGET_CLICKED = 'budgets:request_panel:widget_clicked',

  // Discover
  DISCOVER_LEARN_MORE_FEATURE_PAGE = 'discover:learn_more_from_feature_page',
  DISCOVER_LEARN_MORE = 'discover:learn_more',
  DISCOVER_CONTACT = 'discover:contact_csm',
  DISCOVER_START_TRIAL = 'discover:start_trial',
  DISCOVER_START_NOW = 'discover:start_now',

  // mobile app
  MOBILE_APP_INTERSTITIAL = 'mobile_webapp:interstitial:cta_clicked',
  MOBILE_APP_SCA = 'sca:download_app:button:clicked',

  // Payments
  PAYMENTS_DOWNLOAD_BUTTON_CLICKED = 'payments:download:button_clicked',
  PAYMENTS_SEE_VIRTUAL_CARD_USED = 'payments:see:virtual_card:used',
  PAYMENTS_ALL_FILTERING_CHANGED = 'payments:payments_all:filtering_changed',
  PAYMENTS_PANEL_UPLOAD_RECEIPT_BUTTON_CLICKED = 'payments:panel:new_receipt',

  // Requests
  REQUESTS_CREATED_COST_CENTER_SUGGESTED = 'requests:created:cost_center_suggested',
  REQUEST_PROCUREMENT_BUTTON_CLICKED = 'requests:procurement_process:button:clicked',
  REQUEST_MFA_REQUIRED = 'requests:mfa:required',
  REQUEST_NEW_BUTTON_CLICKED = 'requests:new:clicked',
  REQUEST_NEW_CANCEL_CLICKED = 'requests:new:cancel_clicked',
  REQUEST_PANEL_UPLOAD_RECEIPT = 'requests:panel:upload_receipt',
  REQUEST_PROGRESS_BOX_NAVIGATION_USED = 'requests:progressbox:navigation',
  REQUEST_TIMELINE_OPENED = 'requests:timeline:opened',
  REQUEST_FORM_INPUT_ERROR = 'requests:form:input_error',
  REQUEST_FORM_SUBMITTED_WITH_PO_ATTACHED = 'requests:form:submitted_with_po_attached',
  REQUEST_PO_FORM_SUBMITTED_WITH_NET_GROSS_EQUAL = 'requests:po:form:submitted_with_net_gross_equal',
  REQUEST_TIME_SPENT_ON_PO_PANEL_WHEN_CLOSED = 'requests:time_spent_on_po_panel_when_closed',
  REQUEST_TIME_SPENT_ON_PO_PANEL_WHEN_SUBMITTED = 'requests:time_spent_on_po_panel_when_submitted',
  REQUEST_PO_NET_GREATER_THAN_GROSS = 'requests:po:net_greater_than_gross',
  REQUEST_INVOICE_SUBMITTED_OVERBILLED = 'requests:invoice:submitted_overbilled',
  REQUEST_FIELD_VALIDATION_ERROR = 'requests:po:validation_error',
  REQUEST_BULK_ACTION = 'requests_bulk_action',
  REQUEST_NEW_SEE_EMAIL_ADDRESSES = 'request:new:see_email_adresses_button_clicked',
  REQUEST_NEW_SUBMITTED = 'requests:new:submitted',
  REQUEST_NEW_MILEAGE = 'requests:new:mileage',

  // Requests list
  REQUEST_LIST_FILTERING_CHANGED = 'requests:list:filtering_changed',
  REQUEST_LIST_FILTERING_CHANGED_NEW = 'requests:list:filtering_changed_new',
  REQUESTS_LIST_SEARCH_CHANGED = 'request:list:search_changed',
  REQUESTS_LIST_FILTERS_RESET = 'request:list:filters_reset',

  // Bulk Expense Submit
  SUBMIT_EC_START = 'submit_ec_start',
  SUBMIT_EC_PREFILL_DESCRIPTION = 'submit_ec_prefill_description',
  SUBMIT_EC_SUCCESS = 'submit_ec_success',

  // Rejected request edition
  REJECTED_REQUEST_CREATE_CLICKED = 'request:rejected:create_clicked',
  REJECTED_REQUEST_CANCEL = 'request:rejected:cancel',
  REJECTED_REQUEST_SUBMIT = 'request:rejected:submit',

  // Purchase Order
  PURCHASE_ORDER_REQUEST_HISTORY_CLICKED = 'purchase:order:request:history:clicked',
  PURCHASE_ORDER_DOWNLOAD_SELECTION_CLICKED = 'click_download_po_csv',
  // PO Items
  PURCHASE_ORDER_SPLIT_ITEMS_CLICKED = 'click_po_split_items',
  PURCHASE_ORDER_ADD_ITEM_CLICKED = 'click_po_add_item',
  PURCHASE_ORDER_DELETE_ITEM_CLICKED = 'click_po_delete_item',
  // PO Document
  PURCHASE_ORDER_CREATE_DOCUMENT_CLICKED = 'click_po_create_document',
  PURCHASE_ORDER_EDIT_DOCUMENT_CLICKED = 'click_po_edit_document',
  PURCHASE_ORDER_ADD_LOGO_CLICKED = 'click_po_add_logo',
  PURCHASE_ORDER_ADD_SHIPPING_ADDRESS_CLICKED = 'click_po_add_shipping_address',
  PURCHASE_ORDER_ADD_COMMENTS_CLICKED = 'click_po_add_comments',
  PURCHASE_ORDER_SAVE_DOCUMENT_CLICKED = 'click_po_save_document',
  PURCHASE_ORDER_DOWNLOAD_CLICKED = 'click_po_download',

  // Auth
  LOGIN_FORGOT_PASSWORD_BUTTON_CLICKED = 'login:forgot_password:button:clicked',
  LOGIN_MARKETING_SECTION_BUTTON_CLICKED = 'login:marketing_section:button:clicked',
  SIGNUP_ERROR = 'user_cant_signup',
  SIGNUP_EMAIL_STEP_COMPLETED = 'email_step_signup',
  SIGNUP_IDENTITY_STEP_COMPLETED = 'identity_step_signup',

  // Self-serve signup
  SIGNUP_SELF_SERVE_STEP_COMPLETED = 'signup:self_serve:step_completed',
  SIGNUP_SELF_SERVE_INVALID_EMAIL = 'signup:self_serve:invalid_email',

  // Signup
  SIGNUP_STEP_COMPLETED = 'signup:step_completed',
  SIGNUP_INVALID_EMAIL = 'signup:invalid_email',
  SIGNUP_COMPLETED = 'company_signup',
  GROUP_COMPANY = 'signup:group_company',

  // Cost centers
  COST_CENTERS_MIGRATION_DISCOVER_BUTTON_CLICKED = 'cost_centers:migration:discover:button:clicked',
  COST_CENTERS_MIGRATION_USE_BUTTON_CLICKED = 'cost_centers:migration:use:button:clicked',
  COST_CENTERS_MIGRATION_CONFIRM_BUTTON_CLICKED = 'cost_centers:migration:confirm:button:clicked',
  COST_CENTERS_MIGRATION_CANCEL_BUTTON_CLICKED = 'cost_centers:migration:cancel:button:clicked',

  // Custom fields
  CUSTOM_FIELDS_FORM_POST_VALUES = 'custom_fields:forms:post:values',

  // Beta feature feedback
  BETA_FEATURE_GIVE_FEEDBACK_BUTTON_CLICKED = 'beta_feature:give_feedback:button:clicked',
  BETA_FEATURE_SUBMIT_FEEDBACK_BUTTON_CLICKED = 'beta_feature:submit_feedback:button:clicked',
  BETA_FEATURE_WITH_RATING_MODAL_SUBMIT_FEEDBACK_BUTTON_CLICKED = 'beta_feature_with_rating_modal:submit_feedback:button:clicked',
  BETA_FEATURE_SUBMIT_FEEDBACK_AND_SWITCH_TO_OLD_VERSION_BUTTON_CLICKED = 'beta_feature:submit_feedback_and_switch_to_old_version:button:clicked',

  // Welcome onboarding
  WELCOME_DASHBOARD_LOAD = 'welcome_onboarding:load',

  WELCOME_ONBOARDING_TASK_START = 'welcome_onboarding:task:start',
  WELCOME_ONBOARDING_TASK_UNSKIP = 'welcome_onboarding:task:unskip',
  WELCOME_ONBOARDING_TASK_SKIP = 'welcome_onboarding:task:skip',

  WELCOME_VIDEO_CLICKED = 'welcome_video:clicked',
  WELCOME_WIDGETS_NEED_HELP_CLICKED = 'welcome_widgets:need_help:clicked',
  WELCOME_WIDGETS_EXPLORE_PLANS_CLICKED = 'welcome_widgets:explore_plans:clicked',

  WELCOME_MODAL_LOAD_FUNDS_CLICKED = 'welcome_modal:load_funds:clicked',
  WELCOME_MODAL_LOAD_FUNDS_SKIPPED = 'welcome_modal:load_funds:skipped',

  // Settings
  SETTINGS_WALLET_FUNDING_LINE_COPIED = 'settings:wallet:funding:line_copied',
  SETTINGS_MEMBERS_INVITE_COPY_BUTTON_CLICKED = 'settings:members:invite:copy_button_clicked',
  SETTINGS_MEMBERS_INVITE_SEND_BUTTON_CLICKED = 'settings:members:invite:send_button:clicked',
  SETTINGS_MEMBERS_INVITE_BULK_TEMPLATE_UPLOADED = 'settings:members:invite:bulk_template_uploaded',

  // Approval policies
  APPROVAL_POLICIES_CREATE_POLICY_BUTTON_CLICKED = 'approval_policies:create_policy_button_clicked',
  APPROVAL_POLICIES_UPDATE_POLICY_BUTTON_CLICKED = 'approval_policies:update_policy_button_clicked',

  // Company switch
  COMPANIES_DROPDOWN_COMPANY_CLICK = 'companies:dropdown:company:clicked',

  // SFS KYC
  // Miscellaneous
  KYC_GO_BACK = 'kyc:misc:go_back',
  KYC_RESUME = 'kyc:misc:resume',
  KYC_HEADER_CLICK = 'kyc:misc:header:click',
  // Business information
  KYC_WELCOME_STARTED = 'kyc:welcome:started',
  KYC_ENTITY_TYPE_CONTINUE = 'kyc:entity_type:continue',
  KYC_ENTITY_TYPE_ERROR = 'kyc:entity_type:error',
  KYC_MONTHLY_SPEND_CONTINUE = 'kyc:business_info:monthly_spend:continue',
  KYC_MONTHLY_SPEND_ERROR = 'kyc:business_info:monthly_spend:error',
  KYC_TYPE_OF_EXPENSES_CONTINUE = 'kyc:business_info:type_of_expenses:continue',
  KYC_TYPE_OF_EXPENSES_ERROR = 'kyc:business_info:type_of_expenses:error',
  KYC_NUMBER_OF_USERS_CONTINUE = 'kyc:business_info:number_of_users:continue',
  KYC_NUMBER_OF_USERS_ERROR = 'kyc:business_info:number_of_users:error',
  KYC_COMPANY_SEARCH = 'kyc:business_info:company:search',
  KYC_COMPANY_SEARCH_ERROR = 'kyc:business_info:company:search:error',
  KYC_COMPANY_DETAILS_CONTINUE = 'kyc:business_info:company:details:continue',
  KYC_COMPANY_DETAILS_ERROR = 'kyc:business_info:company:details:error',
  KYC_COMPANY_ADDRESS_CONTINUE = 'kyc:business_info:company:address:continue',
  KYC_COMPANY_ADDRESS_ERROR = 'kyc:business_info:company:address:error',
  KYC_CERTIFICATE_OF_INCORPORATION_REQUEST = 'kyc:business_info:certificate_of_incorporation:request',
  KYC_CERTIFICATE_OF_INCORPORATION_CONTINUE = 'kyc:business_info:certificate_of_incorporation:continue',
  KYC_CERTIFICATE_OF_INCORPORATION_ERROR = 'kyc:business_info:certificate_of_incorporation:error',
  KYC_FUNDING_CONTINUE = 'kyc:business_info:funding:continue',
  KYC_FUNDING_ERROR = 'kyc:business_info:funding:error',
  // Association information
  KYC_ASSOCIATION_CERTIFICATE_REQUEST = 'kyc:business_info:association_certificate:request',
  KYC_ASSOCIATION_CERTIFICATE_CONTINUE = 'kyc:business_info:association_certificate:continue',
  KYC_ASSOCIATION_CERTIFICATE_ERROR = 'kyc:business_info:association_certificate:error',
  KYC_ARTICLES_OF_ASSOCIATION_REQUEST = 'kyc:business_info:articles_of_association:request',
  KYC_ARTICLES_OF_ASSOCIATION_CONTINUE = 'kyc:business_info:articles_of_association:continue',
  KYC_ARTICLES_OF_ASSOCIATION_ERROR = 'kyc:business_info:articles_of_association:error',
  KYC_FINANCIAL_STATEMENTS_REQUEST = 'kyc:business_info:financial_statements:request',
  KYC_FINANCIAL_STATEMENTS_CONTINUE = 'kyc:business_info:financial_statements:continue',
  KYC_FINANCIAL_STATEMENTS_ERROR = 'kyc:business_info:financial_statements:error',
  KYC_GENERAL_ASSEMBLY_MINUTES_REQUEST = 'kyc:business_info:general_assembly_minutes:request',
  KYC_GENERAL_ASSEMBLY_MINUTES_CONTINUE = 'kyc:business_info:general_assembly_minutes:continue',
  KYC_GENERAL_ASSEMBLY_MINUTES_ERROR = 'kyc:business_info:general_assembly_minutes:error',
  // Ownership
  KYC_OWNERSHIP_STARTED = 'kyc:ownership:continue',
  KYC_DIRECTOR_IDENTITY_CONTINUE = 'kyc:ownership:director_identity:continue',
  KYC_DIRECTOR_IDENTITY_ERROR = 'kyc:ownership:director_identity:error',
  KYC_DIRECTOR_IDENTITY_PROOF_CONTINUE = 'kyc:ownership:director_identity_proof:continue',
  KYC_DIRECTOR_IDENTITY_PROOF_ERROR = 'kyc:ownership:director_identity_proof:error',
  KYC_AUTHORIZED_REPRESENTATIVE_CONTINUE = 'kyc:ownership:authorized_representative:continue',
  KYC_POWER_OF_ATTORNEY_REQUEST = 'kyc:ownership:power_of_attorney:request',
  KYC_SIGNING_DIRECTOR_POI_REQUEST = 'kyc:ownership:signing_director_poi:request',
  KYC_POWER_OF_ATTORNEY_CONTINUE = 'kyc:ownership:power_of_attorney:continue',
  KYC_TREASURER_POI_REQUEST = 'kyc:ownership:treasurer_poi:request',
  KYC_POWER_OF_ATTORNEY_ERROR = 'kyc:ownership:power_of_attorney:error',
  KYC_DIRECTOR_SHARES_CONTINUE = 'kyc:ownership:director_shares:continue',
  KYC_BOARD_DIRECTORS_EDIT_OPEN = 'kyc:ownership:board_directors:edit:open',
  KYC_BOARD_DIRECTORS_EDIT_SAVE = 'kyc:ownership:board_directors:edit:save',
  KYC_BOARD_DIRECTORS_EDIT_ERROR = 'kyc:ownership:board_directors:edit:error',
  KYC_BOARD_DIRECTORS_DELETE = 'kyc:ownership:board_directors:delete',
  KYC_BOARD_DIRECTORS_ERROR = 'kyc:ownership:board_directors:error',
  KYC_BOARD_DIRECTORS_PREFILL_REVIEW_CONTINUE = 'kyc:ownership:board_directors_prefill_review:continue',
  KYC_BOARD_DIRECTORS_CONTINUE = 'kyc:ownership:board_directors:continue',
  KYC_BENEFICIAL_OWNERSHIP_CONTINUE = 'kyc:ownership:beneficial_ownership:continue',
  KYC_BENEFICIAL_OWNERSHIP_ERROR = 'kyc:ownership:beneficial_ownership:error',
  KYC_BENEFICIAL_OWNERSHIP_DOCUMENT_REQUEST = 'kyc:ownership:beneficial_ownership_document:request',
  KYC_BENEFICIAL_OWNERSHIP_DOCUMENT_CONTINUE = 'kyc:ownership:beneficial_ownership_document:continue',
  KYC_BENEFICIAL_OWNERSHIP_DOCUMENT_ERROR = 'kyc:ownership:beneficial_ownership_document:error',
  KYC_SHAREHOLDING_CHART_REQUEST = 'kyc:ownership:shareholding_chart:request',
  KYC_SHAREHOLDING_CHART_CONTINUE = 'kyc:ownership:shareholding_chart:continue',
  KYC_SHAREHOLDING_CHART_ERROR = 'kyc:ownership:shareholding_chart:error',
  KYC_BOARD_SHAREHOLDERS_EDIT_OPEN = 'kyc:ownership:board_shareholders:edit:open',
  KYC_BOARD_SHAREHOLDERS_EDIT_SAVE = 'kyc:ownership:board_shareholders:edit:save',
  KYC_BOARD_SHAREHOLDERS_EDIT_ERROR = 'kyc:ownership:board_shareholders:edit:error',
  KYC_BOARD_SHAREHOLDERS_DELETE = 'kyc:ownership:board_shareholders:delete',
  KYC_BOARD_SHAREHOLDERS_ERROR = 'kyc:ownership:board_shareholders:error',
  KYC_BOARD_SHAREHOLDERS_CONTINUE = 'kyc:ownership:board_shareholders:continue',
  KYC_AO_ASSOCIATION_ROLE_CONTINUE = 'kyc:ownership:ao_association_role:continue',
  KYC_AO_ASSOCIATION_ROLE_ERROR = 'kyc:ownership:ao_association_role:error',
  // Account verification
  KYC_EMAIL_VERIFICATION_RESEND = 'kyc:account_verification:email:resend',
  KYC_EMAIL_VERIFICATION_ERROR = 'kyc:account_verification:email:error',
  KYC_EMAIL_VERIFICATION_CONTINUE = 'kyc:account_verification:email:continue',
  // Adyen Beneficial Owner
  KYC_ADYEN_BENEFICIAL_OWNER_ERROR = 'kyc:adyen:beneficial_owner:error',
  // Adyen Organization information
  KYC_ADYEN_ORGANIZATION_INFORMATION_ERROR = 'kyc:adyen:organization_informatino:error',

  KYC_SUBMITTED = 'kyc:submitted',

  // Credit Notes
  CREDIT_NOTES_REQUEST_CREATE = 'credit_notes:request:create',
  CREDIT_NOTES_REQUEST_VALIDATE = 'credit_notes:request:validate',
  CREDIT_NOTES_REQUEST_DEDUCT_REFERENCE_INVOICE_REQUEST = 'credit_notes:request:deduct_reference_invoice_request',
  CREDIT_NOTES_REQUEST_RECORD_FOR_FUTURE_INVOICES = 'credit_notes:request:record_for_future_invoices',
  CREDIT_NOTES_REQUEST_REIMBURSE_OUTSIDE_SPENDESK = 'credit_notes:request:reimburse_outside_spendesk',
  CREDIT_NOTES_MANAGE_SUPPLIER_CREDIT = 'credit_notes:manage_supplier_credit',
  CREDIT_NOTES_MARK_CREDIT_NOTE_REFUNDED_OUTSIDE_SPENDESK = 'credit_notes:mark_credit_note_refunded_outside_spendesk',
  CREDIT_NOTES_DETACH_CREDIT_NOTE_REFUNDED_OUTSIDE_SPENDESK = 'credit_notes:detach_credit_note_refunded_outside_spendesk',
  CREDIT_NOTES_DETACH_CREDIT_NOTE_RECORD_BACK_CREDIT_TO_SUPPLIER = 'credit_notes:detach_credit_note_record_back_credit_to_supplier',

  // Web performances (cf src/core/utils/analytics/events/performances.ts for a description of the vitals)
  PERFS_WEB_VITALS_CLS = 'track_engineering:app_web_vitals:cls',
  PERFS_WEB_VITALS_FID = 'track_engineering:app_web_vitals:fid',
  PERFS_WEB_VITALS_LCP = 'track_engineering:app_web_vitals:lcp',
  PERFS_WEB_VITALS_FCP = 'track_engineering:app_web_vitals:fcp',
  PERFS_WEB_VITALS_INP = 'track_engineering:app_web_vitals:inp',
  PERFS_WEB_VITALS_TTFB = 'track_engineering:app_web_vitals:ttfb',

  // SFS migration events
  SFS_MIGRATION_START_TASK = 'sfs_migration:start_task',
  SFS_MIGRATION_TASK_SUBMITTED = 'sfs_migration:task_submitted',
  SFS_SUBSCRIPTION_CARD_INFO_COPIED = 'sfs_migration:subscription_card_info_copied',
  SFS_MIGRATION_INITIATION_CARD_ORDER_CONTINUE_BUTTON_CLICKED = 'sfs_migration:initiation:card_order:continue_button_clicked',
  SFS_MIGRATION_ACTIVATION_FUND_WALLET_CLOSE_FUND_WALLET = 'sfs_migration:activation:fund_wallet:close_fund_wallet',
  SFS_MIGRATION_ACTIVATION_FUND_WALLET_CONFIRM_TRANSFER_BUTTON_CLICKED = 'sfs_migration:activation:fund_wallet:confirm_transfer_button_clicked',
  SFS_MIGRATION_EXECUTION_RENEW_SUBSCRIPTION_GENERATE_CARD = 'sfs_migration:execution:renew_subscription:generate_card',
  SFS_MIGRATION_EXECUTION_RENEW_SUBSCRIPTION_SUBSCRIPTION_CARD_INFO_COPIED = 'sfs_migration:execution:renew_subscription:subscription_card_info_copied',
  SFS_MIGRATION_EXECUTION_RENEW_SUBSCRIPTION_RETURN_TO_SPENDESK = 'sfs_migration:execution:renew_subscription:return_to_spendesk',
  SFS_MIGRATION_EXECUTION_DASHBOARD_WALLET_DEPOSIT = 'sfs_migration:execution:dashboard:wallet_deposit',
  SFS_MIGRATION_EXECUTION_DASHBOARD_WALLET_VIEW_DETAILS = 'sfs_migration:execution:dashboard:wallet_view_details',
  SFS_MIGRATION_EXECUTION_DASHBOARD_VIEW_DETAILS = 'sfs_migration:execution:dashboard:view_details',
  SFS_MIGRATION_APP_BANNER_BUTTON_CLICKED = 'sfs_migration:app_banner:button_clicked',
  SFS_MIGRATION_TRACKING_LINK_CLICKED = 'sfs_migration:tracking_link:clicked',

  // AUTOCAT UPSELL CALL OUT
  AUTOCAT_UPSELL_CALL_OUT_CLOSED = 'autocat_upsell:call_out:closed',
  AUTOCAT_UPSELL_CALL_OUT_LEARN_MORE_CLICKED = 'autocat_upsell:call_out:learn_more_clicked',

  // HEADER
  HEADER_PROCUREMENT_BUTTON_CLICKED = 'header:procurement:button:clicked',

  // MEMBERS
  MEMBER_DETAILS_MODAL_OPENED = 'member:details:modal:opened',
  MEMBER_EDIT_DETAILS_MODAL_OPENED = 'member:edit_details:modal:opened',
  MEMBER_EDIT_DETAILS_MODAL_CLOSED = 'member:edit_details:modal:closed',
  MEMBER_EDIT_DETAILS_SAVED = 'member:edit_details:saved',
  MEMBER_EDIT_DETAILS_CHANGES_APPLIED = 'member:edit_details:changes_applied',

  // APPROVALS
  APPROVALS_WORKFLOW_SETUP_ADD_APPROVER = 'approvals:workflow_setup:add_approver',
  APPROVALS_WORKFLOW_SETUP_DELETE_APPROVER = 'approvals:workflow_setup:delete_approver',
  APPROVALS_WORKFLOW_SETUP_EDIT_APPROVER = 'approvals:workflow_setup:edit_approver',
  APPROVALS_WORKFLOW_SETUP_SUBMIT = 'approvals:workflow_setup:submit',
  APPROVALS_WORKFLOW_SETUP_ERROR_OCCURRED = 'approvals:workflow_setup:error_occurred',
  APPROVALS_APPROVAL_FLOW_SETUP_CANCELLED = 'approvals:approval_flow_setup:cancelled',

  // ORGANISATION REPORTING
  ORGANISATION_REPORTING_COMPANY_DROPDOWN_LINK_CLIKED = 'organisation_reporting:company_dropdown:link_clicked',
  ORGANISATION_REPORTING_OPEN_ENTITY_BUTTON_CLICKED = 'organisation_reporting:open_entity_button:clicked',
  ORGANISATION_REPORTING_ADD_FUNDS_BUTTON_CLICKED = 'organisation_reporting:add_funds_button:clicked',
  ORGANISATION_REPORTING_REQUESTS_TO_APPROVE_BUTTON_CLICKED = 'organisation_reporting:requests_to_approve_button:clicked',
  ORGANISATION_REPORTING_INVOICES_TO_PAY_BUTTON_CLICKED = 'organisation_reporting:invoices_to_pay_button:clicked',
  ORGANISATION_REPORTING_LATE_RECEIPTS_BUTTON_CLICKED = 'organisation_reporting:late_receipts_button:clicked',
  ORGANISATION_REPORTING_PAYABLES_TO_REVIEW_BUTTON_CLICKED = 'organisation_reporting:payables_to_review_button:clicked',
}
