import { Icon } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import './Tag.scss';

type TagTheme = '' | 'info' | 'success';

type Props = {
  text: string | JSX.Element;
  theme?: TagTheme;
  isDisabled?: boolean;
  hasCloseIcon?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void; // TODO: fixme
  className?: string;
};

const Tag = ({
  text,
  theme = '',
  isDisabled = false,
  hasCloseIcon = false,
  style = undefined,
  onClick = () => {},
  className = '',
}: Props) => (
  <div
    className={cx('Tag', theme, className, {
      'is-disabled': isDisabled,
    })}
    onClick={() => !isDisabled && onClick()}
    style={style}
  >
    <div
      className={cx('Tag-content', { 'has-icon': hasCloseIcon })}
      role="status"
    >
      {text}
      {!!hasCloseIcon && <Icon name="cross" size="s" />}
    </div>
  </div>
);

export default Tag;
