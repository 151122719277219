import cx from 'classnames';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useUser } from 'src/core/modules/app/hooks/useUser';

import styles from './Homepage.module.css';
import { SummerReleaseModal } from './components/SummerReleaseModal';
import { WelcomePanel } from './components/WelcomePanel';
import { WelcomeVideo } from './components/WelcomeVideo';
import { useDisplayMode, useHomepageMode } from './hooks';
import { useShouldDisplayWelcomeVideo } from './hooks/useShouldDisplayWelcomeVideo';
import { getRequestsLink } from '../../app/layout/components/Header/helpers';
import { ControllerHomepage } from '../ControllerHomepage';
import { RequesterHomepage } from '../RequesterHomepage';

export const Homepage = () => {
  const history = useHistory();

  const user = useUser();
  const company = useCompany();

  const mode = useHomepageMode();
  const displayMode = useDisplayMode();

  const shouldDisplayWelcomeVideo = useShouldDisplayWelcomeVideo();

  if (!displayMode) {
    return (
      <Redirect
        to={{
          pathname: getRequestsLink(user, company.id),
          search: history.location.search,
        }}
      />
    );
  }

  return (
    <div
      className={cx(styles.root, {
        [styles.rootDanger]: mode === 'danger',
      })}
    >
      <div className={styles.container}>
        {shouldDisplayWelcomeVideo ? <WelcomeVideo /> : <WelcomePanel />}
        <div>
          {displayMode === 'controller' ? (
            <ControllerHomepage />
          ) : (
            <RequesterHomepage />
          )}
        </div>
        <SummerReleaseModal />
      </div>
    </div>
  );
};
