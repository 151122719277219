import { Avatar } from '@dev-spendesk/grapes';

import { useUser } from 'modules/app/hooks/useUser';

import { AvatarMenuPopover } from './AvatarMenuPopover';

import './AvatarMenu.css';

export const AvatarMenu = () => {
  const user = useUser();

  return (
    <AvatarMenuPopover className="AvatarMenu__button">
      {({ isDropdown, ...triggerProps }) => (
        <div
          id="avatar-menu" // Used in SummerReleaseModal to anchor tooltip
        >
          <Avatar
            className="AvatarMenu__button"
            variant="circle"
            src={user.avatar ?? ''}
            text={user.fullname}
            {...triggerProps}
            /* @ts-expect-error Grapes hacking time: Accessibility hack */
            role="button"
          />
        </div>
      )}
    </AvatarMenuPopover>
  );
};
