import { useQuery } from 'src/core/api/hooks/useQuery';
import { useUserId } from 'src/core/modules/app/hooks/useUserId';

type FeatureExperiment =
  | { outcome: 'success'; variant: string }
  | { outcome: 'error' };

export const useFeatureExperimentQuery = ({
  experimentName,
  isEnabled = true,
}: {
  experimentName: string;
  isEnabled?: boolean;
}) => {
  const userId = useUserId();
  return useQuery<FeatureExperiment>({
    key: ['feature-experiment', userId, experimentName],
    isEnabled,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/feature-experiment/${userId}/${experimentName}`,
    },
    reshapeData: (data) => data,
  });
};
