import { Link, ModalSlideshow } from '@dev-spendesk/grapes';
import { useState } from 'react';
import { Trans } from 'react-i18next';

import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { ProductTour } from 'src/core/common/components/ProductTour';
import { useFeatureExperimentQuery } from 'src/core/common/hooks/useFeatureExperiment';
import {
  type I18nKey,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import { LocalStorageKey } from 'src/core/constants/storage';
import { getLocalItem, setLocalItem } from 'src/core/utils/storage';

type Slide = {
  title: I18nKey;
  content: I18nKey;
  illustration: string;
  link: string;
};

// TODO(GROW-1308): Update illustrations and links
const SLIDES: Slide[] = [
  {
    title: 'homepage.summerRelease.one.title',
    content: 'homepage.summerRelease.one.description',
    illustration: 'https://picsum.photos/648/840',
    link: 'https://www.spendesk.com/',
  },
  {
    title: 'homepage.summerRelease.two.title',
    content: 'homepage.summerRelease.two.description',
    illustration: 'https://placebear.com/432/560',
    link: 'https://www.spendesk.com/',
  },
  {
    title: 'homepage.summerRelease.three.title',
    content: 'homepage.summerRelease.three.description',
    illustration: 'https://picsum.photos/864/1120',
    link: 'https://www.spendesk.com/',
  },
  {
    title: 'homepage.summerRelease.four.title',
    content: 'homepage.summerRelease.four.description',
    illustration: 'https://placebear.com/648/840',
    link: 'https://www.spendesk.com/',
  },
  {
    title: 'homepage.summerRelease.five.title',
    content: 'homepage.summerRelease.five.description',
    illustration: 'https://picsum.photos/432/560',
    link: 'https://www.spendesk.com/',
  },
];

export const SummerReleaseModal = () => {
  const { t } = useTranslation('global');

  const [hasClosedModal, setHasClosedModal] = useState<boolean>(false);
  const hasSeenModal = !!getLocalItem(LocalStorageKey.SummerReleaseModal, true);

  const featureExperimentQueryState = useFeatureExperimentQuery({
    experimentName: 'summer-release-2024-modal',
    isEnabled: !hasClosedModal && !hasSeenModal,
  });
  const featureExperimentResult = unwrapQuery(featureExperimentQueryState);
  const isPartOfExperiment =
    featureExperimentResult?.outcome === 'success' &&
    featureExperimentResult?.variant === 'show-modal';

  // TODO(GROW-1303): Track quarterly_releases:modal:opened

  const closeModalAndTriggerTooltip = () => {
    setHasClosedModal(true);
    setLocalItem(LocalStorageKey.SummerReleaseModal, new Date(), true);
  };

  const onCancel = () => {
    closeModalAndTriggerTooltip();
    // TODO(GROW-1303): Track quarterly_releases:modal:closed with { slide: 0 }
  };

  const onClose = () => {
    closeModalAndTriggerTooltip();
    // TODO(GROW-1303): Track quarterly_releases:modal:closed with { slide: currentIndex }
  };

  const onDone = () => {
    closeModalAndTriggerTooltip();
    // TODO(GROW-1303): Track quarterly_releases:modal:done
  };

  return (
    <>
      <ModalSlideshow
        isOpen={isPartOfExperiment && !hasClosedModal && !hasSeenModal}
        onCancel={onCancel}
        onClose={onClose}
        onDone={onDone}
        slides={SLIDES.map((slide) => ({
          title: t(slide.title),
          content: (
            <div className="flex flex-col items-start gap-m body-l">
              <Trans<I18nKey> i18nKey={slide.content} />
              {/* TODO(GROW-1303): Track quarterly_releases:modal:link_clicked { slide: currentIndex } */}
              <Link className="text-primary" href={slide.link} isExternal>
                {t('homepage.summerRelease.link')}
              </Link>
            </div>
          ),
          illustration: <img src={slide.illustration} alt="" />,
        }))}
        translations={{
          cancel: t('misc.cancel'),
          previous: t('misc.back'),
          next: t('misc.next'),
          done: t('misc.gotIt'),
        }}
      />
      {hasClosedModal && (
        <ProductTour
          steps={[
            {
              target: '#avatar-menu',
              title: t('homepage.summerRelease.tooltip.title'),
              content: t('homepage.summerRelease.tooltip.description'),
              placement: 'bottom-end',
            },
          ]}
          variant="dark"
        />
      )}
    </>
  );
};
