import { Button, colors, IconButton } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';
import { type TooltipRenderProps } from 'react-joyride';

import { useTranslation } from 'common/hooks/useTranslation';

import styles from './ProductTourTooltip.module.css';

type Props = TooltipRenderProps & {
  className?: string;
  variant: 'light' | 'dark';
};

export const ProductTourTooltip = ({
  backProps,
  className,
  closeProps,
  index,
  isLastStep,
  primaryProps,
  size,
  step,
  tooltipProps,
  variant,
}: Props) => {
  const { t } = useTranslation('global');

  const { content, title } = step;

  const hasMultipleSteps = size > 1;
  const stepper = `${index + 1}/${size}`;

  const isLightVariant = variant === 'light';

  return (
    <div
      {...tooltipProps}
      className={cx(className, 'flex', {
        [styles.leftStart]: step.placement === 'left-start',
        [styles.leftEnd]: step.placement === 'left-end',
        [styles.bottomEnd]: step.placement === 'bottom-end',
      })}
    >
      <div
        className={cx(
          'flex w-[276px] flex-col gap-xs rounded-xs p-s shadow-40',
          {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'bg-white': isLightVariant,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'bg-complementary text-white': !isLightVariant,
          },
        )}
      >
        <div className="flex items-baseline justify-between gap-s">
          <div className="title-l">{title}</div>
          <IconButton
            {...closeProps}
            iconColor={isLightVariant ? undefined : colors.white}
            iconName="cross"
            variant="borderless"
            hasNegativeMargins
            aria-label={t('misc.close')}
          />
        </div>
        <div className="body-m">{content}</div>
        <div className="flex items-center justify-between">
          {hasMultipleSteps ? (
            <>
              <div
                className={cx('body-m', {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  'text-neutral-dark': isLightVariant,
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  'text-white': !isLightVariant,
                })}
              >
                {stepper}
              </div>
              <div className="flex gap-xs">
                {index >= 1 && (
                  <Button
                    {...backProps}
                    className={isLightVariant ? undefined : 'text-white'}
                    variant={isLightVariant ? 'secondary' : 'ghost'}
                    text={t('misc.back')}
                  />
                )}
                <Button
                  {...primaryProps}
                  variant={isLightVariant ? 'primary' : 'contrasted'}
                  text={isLastStep ? t('misc.done') : t('misc.next')}
                />
              </div>
            </>
          ) : (
            <div className="flex flex-1 justify-end">
              <Button
                {...primaryProps}
                variant={isLightVariant ? 'primary' : 'contrasted'}
                text={t('misc.gotIt')}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className={cx({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'my-m': step.placement.includes('left'),
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'mx-m h-[16px] -rotate-90': step.placement.includes('bottom'),
        })}
      >
        <svg
          className={cx({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'fill-white': isLightVariant,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'fill-complementary': !isLightVariant,
          })}
          width="16"
          height="20"
          viewBox="0 0 16 20"
        >
          <path
            d="M14.853 8.25169C16.2247 9.0137 16.2247 10.9863 14.853 11.7483L-8.74228e-07 20L0 -7.86805e-07L14.853 8.25169Z"
            fill="inherit"
          />
        </svg>
      </div>
    </div>
  );
};
