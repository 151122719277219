import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import logout from 'src/core/utils/logout';

export const LogoutButton = () => {
  const { t } = useTranslation('global');

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className="LogoutButton"
      href="#"
      onClick={async (event) => {
        event.preventDefault();
        event.stopPropagation();

        await logout();
        window.location.href = '/auth/login';
      }}
    >
      {t('dropdownLinks.logout')}
    </a>
  );
};
