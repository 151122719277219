/* eslint-disable @typescript-eslint/naming-convention */

enum FEATURES {
  LIVE_CHAT_SUPPORT = 'live_chat_support',
  APPROVAL_FLOWS = 'approval_flows',
  SPENDING_POLICIES = 'spending_policies',
  PAYMENT_INBOX = 'payment_inbox', // TOREMOVE
  CUSTOM_FIELDS = 'custom_fields',
  CUSTOM_EXPORTS = 'custom_exports',
  BANK_JOURNAL_EXPORT = 'bank_journal_export',
  ORGANISATION_BRANCHES = 'organisation_branches',
  TEAMS = 'teams',
  TEAM_MANAGER = 'team_manager',
  ERECEIPTS = 'ereceipts',
  PLASTIC_CARDS = 'plastic_cards',
  PHYSICAL_CARD_CONTROL_ADVANCED = 'physical_card_control_advanced',
  CARD_ORDER_EXPRESS_DELIVERY = 'card_order_express_delivery_flow',
  VIRTUAL_CARDS = 'virtual_cards',
  CARDS_ACCESS = 'cards_access',
  PLAY_BY_RULES = 'play_by_rules',
  PLAY_BY_RULES_MULTIPLE = 'play_by_rules_multiple',
  NETSUITE_ACCOUNTING_INTEGRATION = 'netsuite_accounting_integration',
  TRAVELPERK_INTEGRATION = 'integration_travelperk',
  HR_INTEGRATIONS = 'hr_integrations',
  // Technical features,
  ACCOUNTS_USD = 'accounts_usd',
  WALLET_ACCESS = 'wallet_access',
  CASH_WITHDRAWAL = 'cash_withdrawal',
  SLACK_INTEGRATION = 'slack_integration',
  EXPENSE_CLAIMS = 'expense_claims',
  REIMBURSE_EXPENSES_VIA_SEPA = 'reimburse_expenses_via_sepa',
  DRAFTS_EXPENSE_CLAIMS = 'drafts_expense_claims',
  MILEAGE_ALLOWANCES = 'mileage_allowances',
  PER_DIEM_ALLOWANCES = 'per_diem_allowances',
  SUBMIT_MY_INVOICE = 'submit_my_invoice',
  AUTO_CAT = 'auto_cat',
  ML_AUTOMATION = 'ml_automation',
  WIRE_TRANSFER_FOR_EXPENSE_CLAIMS = 'wire_transfer_for_expense_claims',
  BUDGETS = 'budgets',
  BILL_INBOX = 'bill_inbox',
  TMP_LOADS_TO_LIMITS = 'tmp_loads_to_limits',
  COST_CENTERS_ACTIVATED = 'cost_centers_activated',
  COST_CENTERS = 'cost_centers',
  EXPENSE_CATEGORIES = 'expense_categories',
  USERS_COUNT_LIMIT = 'users_count_limit',
  FREEMIUM_USERS_COUNT_LIMIT = 'freemium_user_count_limit',
  PURCHASE_ORDERS = 'purchase_orders',
  PURCHASE_ORDERS_ACTIVATED = 'purchase_orders_activated',
  ALLOCATION_PERIOD = 'allocation_period',
  STRONG_CUSTOMER_AUTHENTICATION = 'strong_customer_authentication',
  UPSELL_CTA = 'upsell_cta',
  WIRE_TRANSFER_FOR_INVOICES = 'wire_transfer_for_invoices',
  DASHBOARD = 'dashboard',
  CONTROL_ON_SPENDING_METHODS = 'control_on_spending_methods',
  CONTROL_ON_PURCHASE_ORDER = 'control_on_purchase_order',
  SPANISH_DPR = 'spanish_dpr',
  TMP_ACCOUNTING_DATE = 'tmp_accounting_date',
  TMP_CUSTOM_FIELD_VALUE_PICKER_REDESIGN = 'tmp_custom_field_value_picker_redesign',
  TMP_HIDE_EXPENSE_ACCOUNTS = 'tmp_accounting_integration_hide_expense_accounts',
  SETTINGS_ACCOUNTING = 'settings_accounting',
  SETTINGS_ACCOUNTS_PAYABLE = 'settings_accounts_payable',
  SETTINGS_ACCOUNTING_EXPORTS = 'settings_accounting_exports',
  SETTINGS_COST_CENTERS = 'settings_cost_centers',
  SUBSCRIPTION_RECURRENCE = 'subscription_recurrence',
  PROCUREMENT = 'procurement',
  PROFILE_USER_SECURITY_INFOS = 'profile_user_security_infos',
  TMP_MEMBER_TABLE_ROWS_QUERY = 'tmp_member_table_rows_query',
  BOOKKEEP = 'bookkeep',
  BOOKKEEP_AMORTISATION = 'bookkeep_amortisation',
  BOOKKEEP_PAYABLES = 'bookkeep_payables',
  ANALYTICAL_SPLIT = 'analytical_split',
  HAS_OPTED_IN_TO_ANALYTICAL_SPLIT = 'has_opted_in_to_analytical_split',
  API_KEYS_PAGE = 'api_keys_page',
  NATIVE_EXPORTS_DISABLED = 'native_exports_disabled',
  FILE_BASED_EXPORTS_DISABLED = 'file_based_exports_disabled',
  PUBLIC_API_DISPLAY_EXPERIMENTAL_SCOPES = 'public_api_display_experimental_scopes',
  TMP_NETSUITE_BETA = 'tmp_native_netsuite_beta',
  MANDATORY_INVOICE_PURCHASE_ORDER = 'mandatory_invoice_purchase_order',
  TMP_GET_AVAILABLE_ACCOUNTING_INTEGRATIONS_USING_REST = 'tmp_get_available_accounting_integrations_using_rest',
  TMP_GET_AVAILABLE_ACCOUNTING_INTEGRATIONS_DIFF_CHECK = 'tmp_get_available_accounting_integrations_diff_check',
  PLAY_BY_THE_RULES_RECEIPT_AND_FIELDS = 'play_by_the_rules_receipt_and_fields',
  TMP_SFS_MIGRATION_PAGE = 'tmp_sfs_migration_page',
  TMP_SFS_MIGRATION_ENABLE_ID_VERIFICATION = 'tmp_sfs_migration_enable_id_verification',
  TMP_SHOW_MARQETA_ADDRESS_FROM_CARD_REPO = 'tmp_show_marqeta_address_from_card_repo',
  TMP_SFS_COLLECT_PHONE_NUMBER_RENEW_CARDS = 'tmp_sfs_collect_phone_number_renew_cards',
  TMP_SFS_MIGRATION_ENABLE_REORDER_SFS_CARD = 'tmp_sfs_migration_enable_reorder_sfs_card',
  TMP_ACTIVATE_RECARD_FLOW = 'tmp_activate_recard_flow',
  WIRE_TRANSFER_DELEGATION = 'wt_delegation',
  TMP_VERTICAL_NAVIGATION = 'tmp_vertical_navigation',
  TMP_SCA_PASSWORD_UK = 'tmp_sca_password_uk',
  TMP_CONMAN_REPORTING_MANAGERS = 'tmp_reporting_managers',
  TMP_PURCHASE_ORDER_PDF_GENERATION = 'tmp_purchase_order_pdf_generation',
  TMP_PURCHASE_ORDER_ITEMIZATION = 'tmp_purchase_order_itemization',
  TMP_MILEAGE_UK_COMPUTATION = 'tmp_mileage_uk_computation',
  TMP_MILEAGE_UK_PASSENGERS = 'tmp_mileage_uk_passengers',
  TMP_MILEAGE_UK_FUEL_RECEIPT = 'tmp_mileage_uk_fuel_receipt',
  ORGANISATION_REPORTING_PAGE = 'organisation_reporting_page',
  TMP_ORGANISATION_REPORTING_GROUP = 'tmp_organisation_reporting_group',
  TMP_ORGANISATION_REPORTING_WALLET = 'tmp_organisation_reporting_wallet',
  TMP_ORGANISATION_REPORTING_REQUESTS_TO_APPROVE = 'tmp_organisation_reporting_requests',
  TMP_ORGANISATION_REPORTING_INVOICES_TO_PAY = 'tmp_organisation_reporting_invoices',
  TMP_ORGANISATION_REPORTING_LATE_RECEIPTS = 'tmp_organisation_reporting_receipts',
  TMP_ORGANISATION_REPORTING_PAYABLES_TO_REVIEW = 'tmp_organisation_reporting_payables',
}

export default FEATURES;
