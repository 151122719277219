import classNames from 'classnames';
import React from 'react';

export type BreakdownGaugeBar = {
  name: string;
  value: number;
  valueLabel?: React.ReactNode;
  valueLabelColor?: 'positive' | 'neutral' | 'warning';
  valueLabelTooltip?: React.ReactNode;
  type: 'full' | 'striped' | 'light' | 'empty';
  hideBar?: boolean;
};

export type BreakdownGaugePreparedBar = ReturnType<
  typeof prepareBreakdownGaugeData
>[number];

export type Props = {
  bars: BreakdownGaugeBar[];
  trend: 'positive' | 'neutral' | 'warning';
  isInactive?: boolean;
  children?: (preparedBars: BreakdownGaugePreparedBar[]) => React.ReactNode;
};

const prepareBreakdownGaugeData = ({
  bars,
  trend,
  isInactive,
}: {
  bars: BreakdownGaugeBar[];
  trend: Props['trend'];
  isInactive: boolean;
}) => {
  const gradientSizes = {
    color: Math.sqrt(2 * 1.5 ** 2), // px
    bg: Math.sqrt(2 * 2.5 ** 2), // px
  };

  return bars.map((bar, index) => {
    const [color, backgroundColor] = (() => {
      if (isInactive && bar.type !== 'empty') {
        return ['var(--color-warning-light)', 'var(--color-warning-lightest)'];
      }

      switch (trend) {
        case 'positive':
          return ['var(--color-primary)', 'var(--color-primary-lightest)'];

        case 'warning':
          return [
            'var(--color-warning-light)',
            'var(--color-warning-lightest)',
          ];

        default:
          return [
            'var(--color-neutral-lighter)',
            'var(--color-neutral-lightest)',
          ];
      }
    })();
    const valueLabelColor = (() => {
      switch (bar.valueLabelColor) {
        case 'positive':
          return 'var(--color-success)';

        case 'warning':
          return 'var(--color-warning-light)';

        case 'neutral':
          return 'var(--color-neutral)';

        default:
          return undefined;
      }
    })();
    const cssBackground = (() => {
      switch (bar.type) {
        case 'full':
          return color;

        case 'striped':
          return `repeating-linear-gradient(
          -45deg,
          ${color} 0px,
          ${color} ${gradientSizes.color}px,
          ${backgroundColor} ${gradientSizes.color}px,
          ${backgroundColor} ${gradientSizes.color + gradientSizes.bg}px
        )`;

        default:
          return backgroundColor;
      }
    })();

    return {
      ...bar,
      valueLabel: bar.valueLabel ?? bar.value,
      valueLabelColor,
      valueLabelTooltip: bar.valueLabelTooltip,
      key: `${bar.value}-${index}`,
      color,
      backgroundColor,
      hideBar: bar.hideBar ?? false,
      cssBackground,
    };
  });
};

export const BreakdownGauge = ({
  bars,
  trend,
  children,
  isInactive = false,
}: Props) => {
  const preparedData = prepareBreakdownGaugeData({ bars, trend, isInactive });
  const preparedBars = preparedData.filter((c) => !c.hideBar && c.value > 0);
  const total = preparedBars.reduce(
    (accumulator, bar) => accumulator + bar.value,
    0,
  );
  const isEmpty = preparedBars.length === 0;

  return (
    <div className="box-border flex w-full min-w-[120px] flex-col flex-nowrap">
      <div className="box-border flex h-s w-full flex-nowrap gap-[2px] overflow-hidden rounded">
        {preparedBars.map((bar) => (
          <div
            key={`bar-${bar.key}`}
            className={classNames('h-s')}
            style={{
              width: `${(bar.value * 100) / total}%`,
              background: bar.cssBackground,
            }}
          />
        ))}
        {(isEmpty || isInactive) && (
          <div
            className={classNames(
              'h-s w-full',
              isInactive ? 'bg-white' : 'bg-neutral-lightest',
              isInactive && 'rounded border border-solid border-neutral-light',
            )}
          />
        )}
      </div>

      {children && (
        <div className="box-border w-full pt-m">{children?.(preparedData)}</div>
      )}
    </div>
  );
};
