import React from 'react';
import Joyride, { type Props as JoyrideProps, type Step } from 'react-joyride';

import { ProductTourTooltip } from './components/ProductTourTooltip';

type Props = {
  className?: string;
  variant?: 'light' | 'dark';
  steps: Step[];
} & Partial<JoyrideProps>;

export const ProductTour = ({
  className,
  variant = 'light',
  steps,
  ...props
}: Props) => {
  const enrichedSteps = steps.map((step) => ({
    ...step,
    disableBeacon: true,
  }));
  return (
    <Joyride
      {...props}
      continuous
      disableOverlay
      disableScrollParentFix
      floaterProps={{
        hideArrow: true,
      }}
      steps={enrichedSteps}
      styles={{
        options: {
          zIndex: 9001, // Grapes' --z-index-modal + 1
        },
      }}
      tooltipComponent={(tooltipProps) => (
        <ProductTourTooltip
          {...tooltipProps}
          className={className}
          variant={variant}
        />
      )}
    />
  );
};
